import Section from './DTO/Response/Section'
import Page from '@/calendesk/models/DTO/Response/Page'

export default class SectionToDuplicate {
  public page: Page;
  public section: Section;

  constructor (section: Section, page: Page) {
    this.page = page
    this.section = section
  }
}
