








































import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import { mapActions, mapGetters } from 'vuex'
import Section from '@/calendesk/models/DTO/Response/Section'
import Page from '@/calendesk/models/DTO/Response/Page'
import { PageType } from '@/calendesk/models/BuilderTypes'
import SectionToDuplicate from '@/calendesk/models/SectionToDuplicate'
import { errorNotification, successNotification } from '@/lib/calendesk-js-library/tools/notification'

export default mixins(DraftElement).extend({
  name: 'DuplicateSectionDialog',
  data () {
    return {
      section: null as Section | null,
      page: null as Page | null,
      pageTypes: PageType,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog/getInstance',
      draftPages: 'builder/getDraftPages'
    }),
    availablePages (): Page[] {
      return this.draftPages.filter((page: Page) => page.type === this.pageTypes.MAIN_PAGE ||
        page.type === this.pageTypes.PAGE)
    }
  },
  created () {
    if (this.dialog.data && Object.keys(this.dialog.data).length) {
      this.section = this.dialog.data

      if (this.availablePages) {
        const foundPage = this.availablePages.find((page) => page.id === this.section?.pageId)

        if (foundPage) {
          this.page = foundPage
        }
      }
    }
  },
  methods: {
    ...mapActions({
      duplicateSection: 'builder/duplicateSection',
      closeDialog: 'dialog/close'
    }),
    duplicate () {
      if (this.section && this.page) {
        this.isLoading = true
        const sectionToDuplicate = new SectionToDuplicate(this.section, this.page)
        this.duplicateSection(sectionToDuplicate).then(() => {
          successNotification('wb_duplicate_section_success_message')
          this.closeDialog()
        }).catch((error) => {
          errorNotification('error_occurred', error)
        }).finally(() => {
          this.isLoading = true
        })
      }
    }
  }
})
